const Input = (props) => {
  const {
    type,
    name,
    id,
    placeholder,
    value,
    onChange = () => {},
    onBlur = () => {},
    hasError = false,
    errorMessage = "error",
  } = props;
  console.log(hasError);
  return (
    <span className="w-full">
      <input
        className={`w-full text-base text-font focus:outline-0 p-3 border  ${
          hasError ? "border-red-600" : "border-black"
        }`}
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <p
        className={`${"text-[14px] leading-none text-red-600 mt-1.5 mb-2.5 transition ease-in duration-200"} ${
          hasError ? "opacity-1 " : "opacity-0"
        }`}
      >
        {errorMessage}
      </p>
    </span>
  );
};

export default Input;
