import { Link } from "react-router-dom";
import { NavigationStyle } from "../../style";
import LongArrowIcon from "../Icons/LongArrowIcon";

const HeaderLink = (props) => {
  const { text, to, onClick } = props;
  return (
    <Link
      className={`${NavigationStyle.link} group/link flex items-center gap-x-4`}
      to={to}
      onClick={onClick}
    >
      {text}
      <span className="translate-x-[-20%] opacity-0 transition ease-in duration-100 group-hover/link:translate-x-[0] group-hover/link:opacity-100">
        <LongArrowIcon color={"rgba(255, 255, 255, 0.6)"} size={"w-7 h-7"} />
      </span>
    </Link>
  );
};

export default HeaderLink;
