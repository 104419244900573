import { Link } from "react-router-dom";
import { logo } from "../../assests/images";

const Logo = () => {
  return (
    <Link to={"/"}>
      <img
        className="w-[14rem] h-[4rem] md:w-[16rem] object-contain lg:w-[20rem] lg:h-[6rem] "
        src={logo}
        alt=""
      />
    </Link>
  );
};

export default Logo;
