import React from "react";
import Container from "../../components/Container/Container";

const Footer = () => {
  return (
    <Container>
      <div className="my-6 flex items-center justify-between text-font text-sm tracking-wide">
        <p className="text-font text-sm tracking-wide">® All rights reserved</p>
        <a href="https://wa.me/96171121461" target="{_blank}" className="">
          Powered by <span className="underline decoration-1">Fady Khalil</span>
        </a>
      </div>
    </Container>
  );
};

export default Footer;
