import { Routes, Route } from "react-router-dom";
// Layout
import Header from "./layout/Header/Header";
import Footer from "./layout/Footer/Footer";
// routes
import {
  Home,
  WhoWeAre,
  OurTeam,
  OurProject,
  Blow,
  ContactUs,
} from "./routes/route";
function App() {
  return (
    <>
      <Header />

      <Routes>
        <Route index element={<Home />} />
        <Route path="whoWeAre" element={<WhoWeAre />} />
        <Route path="team" element={<OurTeam />} />
        <Route path="projects" element={<OurProject />} />
        <Route path="blow" element={<Blow />} />
        <Route path="blow/contact" element={<ContactUs />} />

        <Route path="*" element={<Home />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
