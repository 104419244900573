import { HomeBoxStyle } from "../../../style";

const Content = ({ clasName, content }) => {
  const style =
    `${HomeBoxStyle.box} bg-[#e9e9e9] text-font text-[16px] leading-[1.6] normal-case px-4 py-16 col-span-4 sm:col-span-8 row-span-1 lg:row-span-2 lg:col-span-2  ` +
    clasName;
  return (
    <div className={style}>
      <p>{content}</p>
    </div>
  );
};

export default Content;
