import "./NavBtn.css";

const NavButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="nav-btn">
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

export default NavButton;
