const accountability = [
  "PEP's corruption and ill gotten assets: This component includes the following concrete actions: (i) the location, freezing, and return of ill-gotten assets resulting from corruption, which are located outside Lebanon (ii) judicial and extra-judicial proceedings with the financial and criminal authorities and civilians outside Lebanon to put an end to the impunity of politically exposed persons and their entourage as well as state companies or assimilated (iii) the justiciability relating to the diversion of international aid.",
  "All of the funds confiscated by the authorities at their place of location should be returned to Lebanon, through appropriate channels, to be allocated to high-impact social projects, including education.",
];
const vicitims = [
  "The explosion at the Port of Beirut was an unprecedented tragedy in contemporary history. It left 200 dead and 7,000 injured and disabled, destroyed 77,000 apartments and left some 300,000 people homeless, including 80,000 children.",
  "Beyond the damages estimated at some 4 billion dollars, the victims live the injustice of not being heard. If local actions have certainly been initiated, they all come up against the muzzling of the judicial system and the paralysis of the authorities.",
  "The Foundation ensures the establishment of criminal, civil and commercial procedures, outside Lebanon, in order to bring justice to the victims and obtain the physical and moral reparation they expect.",
];
const covernance = [
  "While the work of truth and national reconciliation is essential to establish a sound foundation for the next generations, it is essential that it be the prelude to a larger movement aimed at establishing the state of law. It involves new governance, the overhaul of institutions, the secularization of Lebanon and the establishment of civil rights for all. We will work in concert with Lebanese organizations which have already initiated concrete actions in Lebanon, particularly at the legislative level.",
];

export { covernance, vicitims, accountability };
