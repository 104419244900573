import { Link } from "react-router-dom";
import { HomeBoxStyle } from "../../../style";
import LongArrowIcon from "../../../components/Icons/LongArrowIcon";
const OurTeam = ({ clasName }) => {
  const style =
    `${HomeBoxStyle.box} ourTeam text-font hover:bg-white cursor-pointer relative group/arrow min-h-[8rem] sm:min-h-[10rem] lg:h-auto col-span-4 row-span-1 ` +
    clasName;
  return (
    <Link className={style} to={"team"}>
      Our Team
      <span className="absolute bottom-[10px] right-[50px] opacity-0 transition-all ease-in transition-300 group-hover/arrow:opacity-100 group-hover/arrow:right-[20px] ">
        <LongArrowIcon color={"#333"} size={"w-7 h-7"} />
      </span>
    </Link>
  );
};

export default OurTeam;
