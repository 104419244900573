import Container from "../../components/Container/Container";
import Input from "../../components/UI/Input";
import useInput from "../../hooks/user-input";

const ContactUs = () => {
  const {
    value: fristNameInput,
    isValid: firstNameIsValid,
    HasError: firstNameHasError,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: firstNameRest,
  } = useInput((value) => value.length > 2);

  const {
    value: lastNameInput,
    isValid: lastNameIsValid,
    HasError: lastNameHasError,
    inputChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: lastNameRest,
  } = useInput((value) => value.length > 2);

  const {
    value: emailInput,
    isValid: emailISValid,
    HasError: emailHasError,

    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlueHandler,
    reset: emailReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });

  const {
    value: messageInput,
    isValid: messageISValid,
    HasError: messageHasError,

    inputChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlueHandler,
    reset: messageReset,
  } = useInput((value) => value.trim("") !== "");

  let formISValid = false;

  if (firstNameIsValid && lastNameIsValid && emailISValid && messageISValid) {
    formISValid = true;
  }

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    if (!formISValid) return;

    const formData = new FormData(event.target);
    formData.append("access_key", "b1607834-db4f-43d2-87ae-dda6286f62ed");

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    if (res.success) {
      alert("Success");
    } else {
      alert("Failed");
    }

    firstNameRest();
    lastNameRest();
    emailReset();
    messageReset();
  };
  return (
    <main className="my-primary">
      <section>
        <Container>
          <div className="lg:w-3/4 lg:mx-auto">
            <h1 className="text-3xl text-center mb-10 text-font">Contact us</h1>
            <form onSubmit={formSubmitHandler}>
              <span className="flex items-center gap-x-6">
                <Input
                  type={"text"}
                  name={"firstName"}
                  id={"firstName"}
                  value={fristNameInput}
                  placeholder={"First name"}
                  onChange={firstNameChangeHandler}
                  onBlur={firstNameBlurHandler}
                  hasError={firstNameHasError}
                  errorMessage={"Please enter valid input for this field"}
                />
                <Input
                  type={"text"}
                  name={"lastName"}
                  id={"lastName"}
                  value={lastNameInput}
                  placeholder={"Last name"}
                  onChange={lastNameChangeHandler}
                  onBlur={lastNameBlurHandler}
                  hasError={lastNameHasError}
                  errorMessage={"Please enter valid input for this field"}
                />
              </span>

              <Input
                type={"email"}
                name={"email"}
                id={"email"}
                placeholder={"Your Email"}
                value={emailInput}
                onChange={emailChangeHandler}
                onBlur={emailBlueHandler}
                hasError={emailHasError}
                errorMessage={
                  "Please enter a valid email address (e.g. john@example.com"
                }
              />
              <div>
                <textarea
                  className={
                    "w-full text-base text-font focus:outline-0 p-3 border border-black"
                  }
                  id="message"
                  name="message"
                  placeholder="Your Message"
                  value={messageInput}
                  onChange={messageChangeHandler}
                  onBlur={messageBlueHandler}
                  rows={3}
                  wrap="soft"
                />
                <p
                  className={`${`text-[14px] leading-none text-red-600 mt-1.5 mb-2.5 transition ease-in duration-200`} ${
                    messageHasError ? "opacity-1" : "opacity-0"
                  }`}
                >
                  Please enter valid input for this field
                </p>
              </div>

              <button
                className={`mt-2 bg-grey px-6 py-2 border border-black ${
                  formISValid ? "cursor-pointer" : "cursor-not-allowed"
                }`}
              >
                Submit
              </button>
            </form>
          </div>
        </Container>
      </section>
    </main>
  );
};

export default ContactUs;
