import React from "react";
import { Link } from "react-router-dom";
//
import { HomeBoxStyle } from "../../../style";
import LongArrowIcon from "../../../components/Icons/LongArrowIcon";

const Blow = ({ clasName }) => {
  const style =
    `${HomeBoxStyle.box} bg-darkBlue text-white relative cursor-pointer group/arrow min-h-[8rem] col-span-4 lg:col-span-2 row-span-1 ` +
    clasName;
  return (
    <Link className={style} to={"blow"}>
      Blow The whistle
      <span className="absolute bottom-[10px] right-[50px] opacity-0 transition-all ease-in transition-300 group-hover/arrow:opacity-100 group-hover/arrow:right-[20px] ">
        <LongArrowIcon color={"#fff"} size={"w-7 h-7"} />
      </span>
    </Link>
  );
};

export default Blow;
