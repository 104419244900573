import AntonellaCaruso from "../assests/images/Antonella-Caruso.jpeg";
import AdamaDieng from "../assests/images/Adama-Dieng.jpeg";
import AlainWerner from "../assests/images/Alain-Werner.jpeg";
import JeanZiegler from "../assests/images/Jean-Ziegler.jpeg";
import HenriThulliez from "../assests/images/Henri-Thulliez.jpeg";
import DaraganHajdukovich from "../assests/images/Daragan-Hajdukovich.jpeg";
import WalidSinno from "../assests/images/Walid-Sinno.jpeg";
import ZenaWakim from "../assests/images/ZenaWakim.jpeg";
import HenriChaoul from "../assests/images/Henri-Chaoul.jpg";

export const TeamContent = [
  {
    image: AntonellaCaruso,
    name: "Antonella Caruso",
    bio: "Antonella is an international diplomat and expert on contemporary islam, the Middle East and West Asia. After having been appointed as special advisor to the Minister of Foreign Affairs in Italy, Antonella became the senior advisor of ENI’s CEO for North Africa and the Middle East. In 2008, she founded Ipalmo-USIP Dialogue on National Reconciliation in Iraq with the endorsement of the Iraqi Government and the support of all political forces in the Iraqi Parliament before taking the head of the Middle East and West Asia Division of Political Affairs with the United Nations. Antonella has been increasingly focusing on ethnic and religious minorities to protect diversity and promote coexistence in cities across the world. She currently sits on the advisory board of the Charney Forum for New Diplomacy.",
  },
  {
    image: AdamaDieng,
    name: "Adama Dieng",
    bio: " well acclaimed human rights expert and lecturer on issues relating to international humanitarian law and human rights law. He was the driving force behind the establishment of the African Court on Human and Peoples’ Rights as well as the draft African Convention on Preventing and Combating Corruption.  He acted as an independent expert for the United Nationals, UNITAR, the International Committee of the Red Cross, the European Commission, the Center of the UN for human rights and the African commission for Human rightsIn 2012 he was appointed as the Under-Secretary General of the United Nations and the special advisor to the UN Secretary General for the prevention of genocide.",
  },
  {
    image: AlainWerner,
    name: "Alain Werner",
    bio: "Alain's vision of the world is one where all forgotten vicitims of mass international crimes should have access to fair and importial justice. and perpetrators are held accountable / Founder of Civitas Maxima, a leading NGO documenting war crimes and pursuing justice for the victims / international humans rights lawyer / Prosector of the sepcial Court for Sierra leone in the case of Charles Taylor / Represented Victims of human right abuses in Cambodia at the trial of Kaing Guek Eav (Duch), in Dakar at the trial of former President of Chad, Hissen Habre and In Switzerland for the first cirminal case for war crimes. He represented victims of the civil war in Liberia ",
  },
  {
    image: JeanZiegler,
    name: "Prof. Jean Ziegler",
    bio: "Member of the Swiss Parliament for the Social democrats from 1092 to 1999 / former vice-president of the Advisory Committee to the United Nation Human Rights Council / Held several positions with the United Nations as Special Rapporteur on the Right to from 2000 to 2008, and member of the Advisory Committee of the UN Human Rights Council from 2008 to 2012 / Former professor of sociology at the University of Geneva and the Sorbonne, Paris / Author, lecturer, and well known for this sentence:'A child who dies from hunger is a murdered child' ",
  },
  {
    image: HenriThulliez,
    name: "Henri Thulliez",
    bio: "Henri is a member of the advisory board. Henri Thulliez is a lawyer and member of the Paris bar, the Director of the Fondation pour l’Egalité des Chances en Afrique and sits as a member of the Human Rights Watch Justice Circle. Afer being appointed as the coordinator at Human Rights Watch of the Hissène Habré case and invited conference master at the University of Connecticut and Salzburg Law School on International Criminal Law, he founded PPLAAF, a Platform to Protect Whistleblowers in Africa which seeks to defend whistleblowers, as well as strategically litigate and advocate on their behalf where their disclosures speak to the public interest of African citizens.",
  },
  {
    image: DaraganHajdukovich,
    name: "Prof. Daragan Hajdukovich",
    bio: "Prof Hajdukovich is a professor of quantum physics and astrophysics who is a worldwide reference for his unique work on quantum vaccum fluctuations, with a 25 year lasting work at CERN. He is known for his social and political activism defending human rights and environmental protection. He has run twice for presidential elections in Montenegro.",
  },
  {
    image: WalidSinno,
    name: "Walid Sinno",
    bio: "Walid graduated in 1991 from Ecole Centrale de Lyon. He holds a degree in Mechanical Engineering and a Masters degree in Petroleum Engineering from the Institut Français du Pétrole. After spending several years working for, among others, Electricité de France and ELF Aquitaine, he founded Tigris Capital Advisors, an international energy consultant advising clients on projects in the field of exploration and production development as well as renewable energy. From an early stage on, Walid was actively promoting social responsibility and the need for empowering local communities. He is an Aikido Master, 3rd Dan and a fervent believer that ”True victory is victory over the self.”",
  },
  {
    image: ZenaWakim,
    name: "Zena Wakim",
    bio: "Zena is an international lawyer qualified as a Swiss attorney and as Solicitor of England & Wales. After becoming a partner in one of the largest law firms in Switzerland, Zena trained as a war crimes investigator with the ICIH and sat for 8 years as a board member of the Interpol Foundation. She focused her career on high level and geopolitically sensitive international cases which include the representation of victims of human right abuses, of whistleblowers and the pursuit of accountability in corruption cases. She has been involved in humanitarian missions on the grounds in Africa, Latin America and most recently in Lesbos on emergency rescue operations with migrants.",
  },
  {
    image: HenriChaoul,
    name: "Henri J. Chaoul",
    bio: "Henri is Founder and Managing Partner of Levantine Partners, a corporate finance boutique-firm based in Beirut and Dubai. In 2020, Henri advised the Minister of Finance on all matters related to the sovereign debt restructuring, the Government Reform Program and was a member of the official delegation representing Lebanon in the negotiations with the IMF. Henri has worked in a number of senior positions globally. Henri was recently the Chief Investment Officer of Alkhabeer Capital in Saudi Arabia and before that worked at a number of global firms including Credit Suisse, Monitor Group, KPMG and A.T. Kearney.   Henri received his B.A. in Economics from the American University of Beirut and his Ph.D. in Economics from Columbia University in New York City.",
  },
];
