import { Link } from "react-router-dom";

import { HomeBoxStyle } from "../../../style";

const OurProjects = ({ clasName }) => {
  const style =
    `${HomeBoxStyle.box} projects text-xl text-white hover:bg-white col-span-4  row-span-1 lg:row-span-2  flex-col gap-y-3 p-6 ` +
    clasName;
  return (
    <Link to={"projects"} className={style}>
      <p className="mb-2">The Foundation actions:</p>
      <p>[accountability]</p>
      <p>[Victims of the port explosion-04.08.2020]</p>
      <p>[Memory and justice]</p>
    </Link>
  );
};

export default OurProjects;
