import React from "react";

export const ContentBox = ({ image, content, bgColor, textColor }) => {
  return (
    <div className={"grid grid-cols-1 lg:grid-cols-2 gap-4 mb-10"}>
      <div className="w-full flex items-stretch">
        <img src={image} alt="" />
      </div>
      <div
        className={`w-full min-h-full p-6 flex items-center justify-center flex-col gap-y-3 text-base  ${bgColor} ${textColor} `}
      >
        {content.map((cnt) => (
          <p>{cnt}</p>
        ))}
      </div>
    </div>
  );
};
export const ContentBoxReseve = ({ image, content, bgColor, textColor }) => {
  return (
    <div className={"grid grid-cols-1 lg:grid-cols-2 gap-4 mb-10"}>
      <div
        className={`w-full min-h-full p-6 flex items-center justify-center flex-col gap-y-3 text-base  ${bgColor} ${textColor} `}
      >
        {content.map((cnt) => (
          <p>{cnt}</p>
        ))}
      </div>

      <div className="w-full flex items-stretch">
        <img src={image} alt="" />
      </div>
    </div>
  );
};
