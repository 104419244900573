import { useState } from "react";
// UI component
import Container from "../../components/Container/Container";
// header components
import Navigation from "./Navigation";
import NavButton from "./NavButton";
import Logo from "./Logo";

const Header = () => {
  const [active, setActive] = useState(false);
  const navigationVisibleHanlder = () => {
    setActive((cur) => !cur);
  };
  return (
    <header className="pt-10 pb-4 overflow-hidden">
      <Container className={"flex items-center justify-between "}>
        <Logo />
        <NavButton onClick={navigationVisibleHanlder} />
      </Container>
      <Navigation active={active} onClick={navigationVisibleHanlder} />
    </header>
  );
};

export default Header;
