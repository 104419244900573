import React from "react";
import Collapse from "../../components/UI/Collapse";
import { TeamContent } from "../../constant/TeamContent";
import Container from "../../components/Container/Container";
import HeaderBox from "../../components/UI/HeaderBox";

const OurTeam = () => {
  return (
    <main className="my-primary">
      <section>
        <Container>
          <h4 className="text-[20px] text-font tracking-wide ">
            The Foundation team carries out transversal work and brings together
            multiple skills. It is supported by actors of Lebanese civil
            society, members of various parliaments in Europe, Lebanese and
            international media, intellectuals, artists and activists from
            various backgrounds. This support ensures both transversality in the
            exchange of information, synergy between the various actions carried
            out on concordant projects, and awareness of the issues with the
            public and foreign authorities.
          </h4>

          <HeaderBox>BOARD OF DIRECTORS AND ADVISORS</HeaderBox>

          <div>
            {TeamContent.map((cnt) => (
              <Collapse title={cnt.name} image={cnt.image} bio={cnt.bio} />
            ))}
          </div>
        </Container>
      </section>
    </main>
  );
};

export default OurTeam;
