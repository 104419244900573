import React, { useState } from "react";
import PlusIcon from "../Icons/PlusIcon";
import MinusIcon from "../Icons/MinusIcon";

const Collapse = ({ title, image, bio, isOpenDefault = false }) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border border-grey ">
      <div
        className="flex items-center justify-between cursor-pointer "
        onClick={toggle}
      >
        <span className="flex items-center gap-x-2 hover:bg-grey w-full p-4 border-b border-grey">
          {!isOpen && <PlusIcon color={"#666"} size={"w-6 h-6"} />}
          {isOpen && <MinusIcon color={"#666"} size={"w-6 h-6"} />}
          <h3 className="text-[19px] font-black text-[#666]">{title}</h3>
        </span>
      </div>
      {isOpen && (
        <div className="team-grid ">
          <div className="">
            <img src={image} alt="" className="w-full h-full object-cover" />
          </div>

          <p className="bg-grey p-4 ">{bio}</p>
        </div>
      )}
    </div>
  );
};

export default Collapse;
