import Container from "../../components/Container/Container";
import { ContentBox } from "../../components/UI/ContentBox";
import { ContentBoxReseve } from "../../components/UI/ContentBox";

import {
  accountabilty,
  victims,
  coveernance,
  projectMain,
} from "../../assests/images/index";

import {
  covernance,
  vicitims,
  accountability,
} from "../../constant/OurProjectContent";

const OurProject = () => {
  return (
    <main className="my-primary">
      <section>
        <div className="mb-24 relative h-[90vh] w-full">
          <img
            src={projectMain}
            className={"h-full w-full object-cover"}
            alt=""
          />
          <h2 className="text-4xl lg:text-6xl text-white text-center absolute bottom-[15%] left-1/2 -translate-x-1/2 w-full object-cover">
            Our Projects
          </h2>
        </div>
        <Container>
          <ContentBoxReseve
            image={accountabilty}
            content={accountability}
            bgColor={"bg-grey"}
            textColor={"text-black"}
          />
          <ContentBox
            image={victims}
            content={vicitims}
            bgColor={"bg-white"}
            textColor={"text-black"}
          />
          <ContentBoxReseve
            image={coveernance}
            content={covernance}
            bgColor={"bg-darkBlue"}
            textColor={"text-white"}
          />

          <div className="mt-24">
            <span className="text-font text-sm ">IN BRIEF</span>
            <p className="text-font text-5xl">❝</p>
            <p className="text-font text-[18px] tracking-wider">
              All of the funds confiscated by the authorities at their place of
              location should be returned to Lebanon, through appropriate
              channels, to be allocated to high-impact social projects,
              including education.
            </p>
          </div>
        </Container>
      </section>
    </main>
  );
};

export default OurProject;
