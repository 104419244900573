import Container from "../../components/Container/Container";
// Home Components
import {
  OurTeam,
  WhoWeAre,
  OurProjects,
  Blow,
  Content,
  Images,
} from "./components/index";

const Home = () => {
  return (
    <main className="my-primary">
      <Container>
        <div className="grid grid-cols-4 sm:grid-cols-8 grid-row-10 lg:grid-cols-10 lg:grid-rows-4 gap-6 ">
          <WhoWeAre />
          <OurTeam />
          <Content
            content={
              "  Accountability Now is a Swiss foundation whose mission is to support Lebanese civil society in its desire to put an end to the impunity of leaders who have promoted their private interests to the detriment of that of the Lebanese people"
            }
          />
          <Blow />
          <OurProjects />
          <Images
            className={"bg-image image-one row-span-1 col-span-4 lg:col-span-2"}
          />
          <Images
            className={"bg-image image-two row-span-1 col-span-4 lg:col-span-2"}
          />
          <Images
            className={
              "bg-image image-three row-span-2 col-span-4 sm:col-span-8 lg:col-span-4"
            }
          />
          <Content
            content={
              "We act without political, religious or sectarian considerations. It's our mission to give back to the Lebanese people what they have been deprived of: a State of Law"
            }
          />
          <Images
            className={
              "bg-image image-four row-span-1 col-span-4  sm:col-span-8 lg:col-span-4"
            }
          />
        </div>
      </Container>
    </main>
  );
};

export default Home;
