export const NavigationStyle = {
  link: "uppercase text-lightWhite hover:text-white",
};

export const HomeBoxStyle = {
  box: "flex items-center justify-center font-bold text-[1.3rem] leading-[1.1] uppercase text-center  transition-all ease-in duration-100 h-full px-4",
};

export const whoWeAreStyle = {
  box: "flex items-center mb-10 ",
};

export const headerBox = {
  box: "bg-darkBlue",
  header: "text-white",
};
