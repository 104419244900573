import Container from "../../components/Container/Container";
import HeaderBox from "../../components/UI/HeaderBox";
import { whoWeAre1, whoWeAre2 } from "../../assests/images/index";
import { ContentBox } from "../../components/UI/ContentBox";
import { ContentBoxReseve } from "../../components/UI/ContentBox";

const WhoWeAre = () => {
  const contentOne = [
    " Accountability Now is a Swiss foundation whose mission is to support Lebanese civil society in its desire to put an end to the impunity of leaders who have promoted their private interests to the detriment of that of the Lebanese people.",
    " Reconstruction of the country involves both ending the impunity that has prevailed since the end of the civil war and restoring citizens' confidence in their institutions through political and societal reforms.",
    "  The will for strong and decisive action is indisputable at all levels of Lebanese society. However, and even though many actions have been initiated in Lebanon by citizen associations, unions, independent lawyers and NGOs, they all come up against the muzzling of the judicial system.",
  ];
  const contentTwo = [
    " The Foundation's action responds to this paralysis and provides a concrete response to the demands of civil society. It is the first structured and institutionalized initiative, outside of Lebanon, which brings together lawyers, jurists,entrepreneurs, communication experts and lobbyists. The Board of Directors will ensure adequate supervision of the activities and the Supervisory Authority, at the Swiss Ministry of Interior, will be responsible for the use of funds and donations received.",
    "Accountability Now acts without political, religious or sectarian consideration. Its mission is to give back to the Lebanese people what they have been deprived of: a state of law.",
  ];
  return (
    <main className="my-primary">
      <section>
        <Container>
          <div>
            <ContentBox
              image={whoWeAre1}
              content={contentOne}
              bgColor={"bg-darkBlue"}
              textColor={"text-white"}
              order={1}
            />
            <ContentBoxReseve
              image={whoWeAre2}
              content={contentTwo}
              bgColor={"bg-grey"}
              textColor={"text-black"}
              order={2}
            />
          </div>

          <HeaderBox>
            <a
              href="mailto:info@acts-now.com"
              target={"_blank"}
              rel="noreferrer"
            >
              if you'd like to contact us please send us an email
            </a>
          </HeaderBox>
        </Container>
      </section>
    </main>
  );
};

export default WhoWeAre;
