import HeaderLink from "../../components/UI/HeaderLink";

const Navigation = ({ active, onClick }) => {
  return (
    <nav
      className={`fixed h-[100vh] w-[75vw] sm:w-[50vw] lg:w-[28vw]  top-0 bg-black transition-all ease-in duration-300 px-10 pt-28 z-[100] ${
        active ? "right-0" : "right-[-100%]"
      }`}
    >
      <button
        className="absolute right-[15%] top-[6%] w-[1.4rem] flex flex-col gap-y-3 "
        onClick={onClick}
      >
        <span className={`h-[1px] w-full bg-white rotate-[-135deg]`}></span>
        <span
          className={`h-[1px] w-full bg-white rotate-[135deg] absolute`}
        ></span>
      </button>
      <ul className="flex flex-col gap-y-10 ">
        <HeaderLink text={"Who We are"} to={"whoWeAre"} onClick={onClick} />
        <HeaderLink text={"our team"} to={"team"} onClick={onClick} />
        <HeaderLink text={"our projects"} to={"projects"} onClick={onClick} />
        <HeaderLink text={"blow the whistle"} to={"blow"} onClick={onClick} />
        <HeaderLink text={"Media & publications"} to={"/"} onClick={onClick} />
      </ul>
    </nav>
  );
};

export default Navigation;
