import { Link } from "react-router-dom";
import Container from "../../components/Container/Container";
import MinusIcon from "../../components/Icons/MinusIcon";
const Blow = () => {
  return (
    <main className={"my-primary"}>
      <section>
        <Container>
          <div className="bg-grey p-6">
            <h2 className="text-2xl text-font mb-3">BLOW THE WHISTLE</h2>
            <p className="text-[18px] text-font tracking-wide">
              Whistleblowing is one of the most effective ways to detect and
              prevent corruption and other malpractice. Whistleblowers’
              disclosures have exposed wrongdoing and fraud, avoid disasters for
              health and the environment. Whistleblower’s important role in safe
              guarding the public good is repeatedly proved by the scandals they
              uncover. If you have any information which can help us hold pursue
              justice and accountability, please{" "}
              <Link className="underline" to={"/blow/contact"}>
                contact us
              </Link>
              .
            </p>
          </div>

          <div className="mt-14 mb-24">
            <p className="text-font text-[18px] flex items-center gap-x-2">
              <span className="text-4xl text-font">❝ </span>
              <span>
                <MinusIcon color={"#333"} size={"w-6 h-6"} />
              </span>{" "}
              Whistleblower’s important role in safe guarding the public good is
              repeatedly proved by the scandals they uncover
            </p>
          </div>
        </Container>
      </section>
    </main>
  );
};

export default Blow;
